import "./Navbar.css";
import React from "react";


export default function Navbar() {


    return(
        <div className="navbar-container">
            <h1 className="navbar-title">Diction Daily <img className="daily-diction-logo" src='/images/daily_diction_logo.png' alt="Diction Daily" /></h1>
            <div className="navbar-buttons">
                
                {
                    (window.location.pathname === "/") ? 
                    <button className="navbar-button" onClick={ () => handleNavbarButtonClick("Home") } title="Home"><img className="button-img" src='/images/home2.png' alt="Home" style={{borderBottom: "3px solid black"}}/></button> : 
                    <button className="navbar-button" onClick={ () => handleNavbarButtonClick("Home") } title="Home"><img className="button-img" src='/images/home2.png' alt="Home"/></button>
                }
                
                {
                    (window.location.pathname === "/about_us") ? 
                    <button className="navbar-button" onClick={ () => handleNavbarButtonClick("About Us") } title="About Us"><img className="button-img" src='/images/about_us1.png' alt="About Us" style={{borderBottom: "3px solid black"}}/></button> : 
                    <button className="navbar-button" onClick={ () => handleNavbarButtonClick("About Us") } title="About Us"><img className="button-img" src='/images/about_us1.png' alt="About Us"/></button>
                }
            
            </div>
        </div>
    );
}


function handleNavbarButtonClick (_button_name) {
    if (_button_name === "Home" && window.location.pathname !== "/") {
        window.location.href = "/";
    } else if (_button_name ===  "About Us" && window.location.pathname !== "/about_us") {
        window.location.href = "/about_us";
    }
}
