import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Home from "./Home.js";
import AboutUs from './AboutUs.js';
import PrivacyPolicy from './PrivacyPolicy.js';
import TermsOfService from './TermsOfService.js';

function App() {
  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route index element={ <Home /> } />
          <Route path="/about_us" element={ <AboutUs /> } />
          <Route path="/privacy_policy" element={ <PrivacyPolicy /> } />
          <Route path="/terms_of_service" element={ <TermsOfService /> } />
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
