import "./AboutUs.css";
import Navbar from "./Navbar.js";
import Footer from "./Footer.js";
import "./Word.css"

export default function AboutUs() {


    return (
        <>
            <Navbar />
            <div className="content-container">
                <div className="content">
                    <h2>About Us</h2>
                    <p className="paragraph">
                        <>Diction Daily was born from a simple yet powerful idea shared by a friend in my English class back in my junior year of 2023. Every day, </>
                        <>they would teach me a new word: often one I'd never heard before. These small, daily lessons sparked a passion for expanding </>
                        <>my vocabulary and realizing the profound impact it can have on communication. A strong vocabulary doesn’t just make conversations </>
                        <>richer; it can help make a lasting first impression. When you speak with precision and intelligence, people are more likely to view </>
                        <>you as smart, confident, and capable. Diction Daily exists to bring that power to everyone by offering a new word each day, helping </>
                        <>you elevate your language and make a remarkable impact in every conversation.</>
                    </p>
                    <p className="header">
                        For any questions, feedback, or partnership opportunities, feel free to contact us at: <a href="mailto:dictiondailysite@gmail.com" target="_blank" rel="noopener noreferrer" >dictiondailysite@gmail.com</a>
                    </p>
                </div>
            </div>
            <Footer />
        </>
    )
}