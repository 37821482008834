import "./TermsOfService.css";
import Navbar from "./Navbar.js";
import Footer from "./Footer.js";


export default function TermsOfService() {



    return(
        <>
            <Navbar />
                <div className="content-container">
                    <div className="content">
                        
                        <h2 className="header">Terms of Service for Diction Daily</h2>
                        
                        <h3 className="header">Effective Date: December 20, 2024</h3>
                        <p className="paragraph">
                            Welcome to Diction Daily! These Terms of Service ("Terms") govern your access to and use of the Diction Daily website, app, and services ("Service"). By accessing or using the Service, you agree to these Terms.
                        </p>

                        <p className="header">
                            If you do not agree to these Terms, please do not use the Service.
                        </p>
                        
                        <h3 className="header">1. Acceptance of Terms</h3>
                        <p className="paragraph">
                            We do not collect any personal information directly from visitors unless they voluntarily provide it (e.g., sending an email directly to Diction Daily). Examples of personal information include:
                        </p>
                        
                        <h3 className="header">2. Our Services</h3>
                        <ul className="header">
                            <li>Diction Daily provides daily vocabulary words and related educational content to help improve language skills.</li>
                            <li>You agree to use the Service for personal, non-commercial purposes.</li>
                            <li>You must not copy, reproduce, or distribute content from Diction Daily without permission.</li>
                        </ul>

                        <h3 className="header">3. User Conduct</h3>
                        <p className="header">
                            By using the Service, you agree not to:
                        </p>
                        <ul className="header">
        	                <li>Violate any applicable laws or regulations.</li>
                            <li>Post or share harmful, offensive, or inappropriate content.</li>
                            <li>Attempt to disrupt or interfere with the Service's operation or security.</li>
                            <li>Use the Service for unauthorized commercial purposes.</li>
                        </ul>

                        <h3 className="header">4. Intellectual Property</h3>
                        <p className="paragraph">
                            All content provided by Diction Daily, including text, graphics, logos, and images, is the property of Diction Daily or its content providers. You may not reproduce or distribute any part of the Service without written permission.
                        </p>

                        <h3 className="header">5. Privacy Policy</h3>
                        <p className="header">
                            Your privacy is important to us. Please refer to our Privacy Policy to understand how we collect, use, and protect your information.
                        </p>

                        <h3 className="header">6. Disclaimers</h3>
                        <ul className="header">
                            <li>The Service is provided "as is" and "as available" without warranties of any kind, either express or implied.</li>
                            <li>While we strive to provide accurate and up-to-date information, Diction Daily makes no guarantees regarding the accuracy or completeness of its content.</li>
                        </ul>

                        <h3 className="header">7. Limitation of Liability</h3>
                        <p className="paragraph">
                            To the fullest extent permitted by law, Diction Daily will not be liable for any direct, indirect, incidental, or consequential damages resulting from your use of the Service.
                        </p>
                        
                        <h3 className="header">8. Termination</h3>
                        <p className="header">
                            We reserve the right to terminate or suspend your access to the Service at any time if you violate these Terms.
                        </p>

                        <h3 className="header">9. Governing Law</h3>
                        <p className="paragraph">
                            These Terms shall be governed by and interpreted in accordance with the laws of California, United States, without regard to its conflict of laws principles.
                        </p>

                        <h3 className="header">10. Contact Us</h3>
                        <p className="header">
                            If you have any questions or concerns regarding these Terms, please contact us at:
                        </p>
                        <ul className="header">
                            <li>Email: <a href="mailto:dictiondailysite@gmail.com" target="_blank" rel="noopener noreferrer" >dictiondailysite@gmail.com</a></li>
                        </ul>
                    </div>
                </div>
            <Footer />
        </>
    );
}